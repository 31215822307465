import {addBreadcrumb as registerSentryEvent} from '@sentry/react';
import {webPushService} from 'store/services/webPushService';

export function register(): void {
  // verifies if service worker is supported by the browser
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL('', window.location.href);

    if (publicUrl.origin === window.location.origin) {
      // if is the correct domain, register the service worker
      // after the page has loaded
      window.addEventListener('load', () => {
        registerValidSW(`/service-worker.js`);
      });
    }
  }
}

async function registerValidSW(swUrl: string) {
  try {
    // register the service worker
    await navigator?.serviceWorker?.register(swUrl);
  } catch (error) {
    // deal with registration error
    registerSentryEvent({
      category: 'service-worker',
      message: `service worker instalation error ${error}`,
      level: 'error',
    });

    console.error('Error during service worker registration:', error);
  }
}

function getBrowser() {
  if (browserInformation?.includes('Firefox')) {
    return 'FIREFOX';
  }

  if (
    browserInformation?.includes('Edg') ||
    browserInformation?.includes('Edge')
  ) {
    return 'EDGE';
  }

  if (
    browserInformation?.includes('OPR') ||
    browserInformation?.includes('Opera')
  ) {
    return 'OPERA';
  }

  if (browserInformation?.includes('Chrome')) {
    return 'CHROME';
  }

  return '';
}

const browserInformation = navigator?.userAgent;

async function subscribeToNotifications(
  registration: ServiceWorkerRegistration,
) {
  try {
    const notificationSubscription = await registration.pushManager.subscribe({
      applicationServerKey: process.env.REACT_APP_VAPID_KEY,
      userVisibleOnly: true,
    });

    const notificationSubscriptionJSON = notificationSubscription.toJSON();

    webPushService({
      p256dh: notificationSubscriptionJSON.keys?.p256dh,
      auth: notificationSubscriptionJSON.keys?.auth,
      registration_id: notificationSubscriptionJSON.endpoint,
      browser: getBrowser(),
    });
  } catch (err) {
    console.error('Error requesting permission', err);
  }
}

const applicationState = localStorage.getItem('letrusState');

const parsedApplicationState = applicationState
  ? JSON.parse(applicationState)
  : undefined;

const isAuthenticated =
  parsedApplicationState?.authentication?.data?.is_authenticated;

if (isAuthenticated) {
  navigator?.serviceWorker?.ready?.then(async (registration) => {
    // Check if notification permission has been granted
    const subscriptionState =
      await registration?.pushManager?.getSubscription();

    if (Notification.permission !== 'granted') {
      const permissionStatus = await Notification.requestPermission();

      if (permissionStatus === 'granted') {
        await subscribeToNotifications(registration);
      }

      // TODO: add code when permission is denied
    }

    if (Notification.permission === 'granted' && !subscriptionState) {
      await subscribeToNotifications(registration);
    }
  });
}
